import gql from "graphql-tag";
import {
  START_PAGE_FIELDS,
  GOLF_AREA_FIELDS,
  CLUB_ATTRIBUTES,
  TIMETABLE_ATTRIBUTES,
  ORDER_BASE_ATTRIBUTES,
  ORDER_CLUBS,
  AFFILIATE_ATTRIBUTES,
  COMMENT_ATTRIBUTES,
  EMAIL_RECIPIENTS_ATTRIBUTES,
  EMAIL_TEMPLATES_ATTRIBUTES,
  INVOICE_BASE_ATTRIBUTES,
  VIRTUAL_CARD_ATTRIBUTES,
  SITE_FIELDS
} from "./GraphQLFields";

//#region  CLUBS
export const GET_CLUB_BY_ID_QUERY = gql`
  query GetClubById($id: ID!) {
    getClubById(_id: $id) {
      ${CLUB_ATTRIBUTES}
    }
  }
`;

export const CLUB_GET_BY_SLUG = gql`
  query GetClubBySlug($slug: String) {
    getClubBySlug(slug: $slug) {
      ${CLUB_ATTRIBUTES}
    }
  }
`;

export const COURSE_TIMETABLES = gql`
  query GetTeeSheetsByCourseId($id: ID!) {
    getTeeSheetsByCourseId(id: $id) {
      ${TIMETABLE_ATTRIBUTES}
    }
  }
`;

export const GET_COURSES = gql`
  query courseSearch($searchTerm: String!) {
    courseSearch(searchTerm: $searchTerm) {
      ok
      courses {
        _id
        name
      }
    }
  }
`;

export const CLUB_SEARCH_PAGE = gql`
  query ClubSearchPage(
    $index: Int!
    $sorting: ClubSortInput!
    $filter: ClubFilterOptionInput
  ) {
    clubSearchPage(pageIndex: $index, sorting: $sorting, filter: $filter) {
      _id
      teeSheetProvider
      name
      courses {
        name
        active
      }
      city
      country
      claimed
      slug
      updatedAt
    }
  }
`;

export const CLUB_SEARCH = gql`
  query ClubSearch($filter: ClubFilterOptionInput) {
    clubSearch(filter: $filter) {
      matchCount
      pageCount
    }
  }
`;

export const COURSES_EXPIRING_RATES = gql`
  query RatesExpirationStatus($days: Int) {
    ratesExpirationStatus(days: $days) {
      _id
      clubSlug
      courseSlug
      courseName
      provider
      ratedDaysLeft
    }
  }
`;
//#endregion

//#region COURSES
export const LINKABLE_COURSES = gql`
  query getActiveCourses {
    getActiveCourses {
      _id
      name
      description
      i18n
    }
  }
`;

export const COURSES_MISSING_AVAILABILITY = gql`
  query coursesMissingAvailability {
    coursesMissingAvailability {
      name
      clubSlug
      slug
      teeSheetProvider
    }
  }
`;
//#endregion

//#region GOLF AREAS
export const GOLF_AREAS = gql`
  query GolfAreas($publishStatus: PublishStatus) {
    GolfAreas(publishStatus: $publishStatus) {
      _id
      name
      slug
      country
      countryCode
      published
      courses {
        _id
      }
    }
  }
`;

export const GOLF_AREA_BY_SLUG = gql`
  query GolfArea($slug: String!) {
    GolfArea(slug: $slug) {
      ${GOLF_AREA_FIELDS}
    }
  }
`;
//#endregion

//#region  START PAGE
export const START_PAGE = gql`
  query StartPage {
    startPage {
      ${START_PAGE_FIELDS}
    }
  }
`;

export const IMAGES_BY_TAG = gql`
  query ImagesByTag($tag: String!) {
    ImagesByTag(tag: $tag) {
      publicId
      width
      height
      format
      bytes
    }
  }
`;
//#endregion

// ACCOUNT MANAGERS
export const ACCOUNT_MANAGERS = gql`
  query AccountManagers {
    accountManagers {
      _id
      firstName
      lastName
      #email
      #updatedAt
    }
  }
`;

// CURRENCIES
export const AVAILABLE_CURRENCIES = gql`
  query AvailableCurrencies {
    availableCurrencies
  }
`;
export const GET_CURRENCIES = gql`
  query GetCurrencies {
    getCurrencies {
      _id
      name
      value
    }
  }
`;
// RATES
export const MISSING_RATES = gql`
  query missingRates($muted: Boolean) {
    missingRates(muted: $muted) {
      clubName
      clubSlug
      courseName
      courseSlug
      provider
      missingRateSchedules {
        seasonId
        seasonType {
          name
          type
          color
        }
        scheduleType
        dayType {
          name
          type
        }
        seasonStartDate
        missingRates {
          _id
          name
          rateTypeId
          muted
          encounterSpan {
            first
            last
          }
        }
      }
    }
  }
`;

//#region  ORDERS
export const ORDER_SEARCH = gql`
  query orderSearch($filter: OrderSearchFilter) {
    orderSearch(filter: $filter) {
      matchCount
      pageCount
      teeTimesCount
      playersCount
      orderValueSum
    }
  }
`;

export const ORDER_LIST = gql`
  query orderList($pageIndex: Int!, $filter: OrderSearchFilter) {
    orderList(pageIndex: $pageIndex, filter: $filter) {
      ${ORDER_BASE_ATTRIBUTES}
      clubs {
        clubId{
        courses{
          _id
          slug
          amadeusActive
          }
        }
        teetimes {
          courseId
          courseName
          playDate
          currency
        }
      }
    }
  }
`;

export const ORDER = gql`
  query order($id: ID!) {
    order(id: $id) {
      order {
        ${ORDER_BASE_ATTRIBUTES}
        ${ORDER_CLUBS}
      }
      offlineBookings {
        _id
        bookingStatus
        courseId
      }
    }
  }
`;

export const ORDER_GET_COMMENTS = gql`
  query getOrderComments($orderId: ID!) {
    getOrderComments(orderId: $orderId) {
      ${COMMENT_ATTRIBUTES}
    }
  }
`;

export const ORDER_GET_LOGS = gql`
  query getOrderLogs($documentId: ID!) {
    getOrderLogs(documentId: $documentId) {
      _id
      documentId
      eventType
      documentType
      changes {
        path
        oldValue
        newValue
      }
      createdAt
      createdBy
    }
  }
`;

export const LIST_ALL_BOOKED_COURSES = gql`
  query allBookedCourses {
    allBookedCourses {
      _id
      name
    }
  }
`;
//#endregion

//#region VOUCHERS
export const VOUCHERS = gql`
  query {
    vouchers {
      _id
      code
      value
      startDate
      endDate
      comment
    }
  }
`;

export const VOUCHER = gql`
  query voucher($id: ID!) {
    voucher(voucherId: $id) {
      _id
      code
      value
      startDate
      endDate
      comment
    }
  }
`;
//#endregion

//#region  Affiliates
export const AFFILIATES = gql`
query {
  affiliates {
    ${AFFILIATE_ATTRIBUTES}
  }
}
`;

export const AFFILIATE = gql`
  query affiliate($id: ID!) {
    affiliate(affiliateId: $id) {
      ${AFFILIATE_ATTRIBUTES}
  }
}
`;
//#endregion

//#region SITES
export const SITES = gql`
  query {
    sites {
      _id
      name
    }
  }
`;

export const SITE = gql`
  query site($id: ID!) {
    site(siteId: $id) {
      ${SITE_FIELDS}
    }
  }
`;
//#endregion

//#region PARTNER_NETWORKS
export const PARTNER_NETWORKS = gql`
  query {
    partnerNetworks {
      _id
      name
      value
      users {
        registered
      }
    }
  }
`;

export const PARTNER_NETWORK = gql`
  query partnerNetwork($id: ID!) {
    partnerNetwork(partnerNetworkId: $id) {
      _id
      name
      value
      users {
        email
        registered
        verified
      }
    }
  }
`;
//#endregion

//#region TOUR_OPERATORS
export const TOUR_OPERATORS = gql`
  query {
    tourOperators {
      _id
      name
      value
      users {
        registered
      }
    }
  }
`;

export const TOUR_OPERATOR = gql`
  query tourOperator($id: ID!) {
    tourOperator(tourOperatorId: $id) {
      _id
      name
      value
      users {
        email
        registered
        verified
      }
    }
  }
`;
//#endregion

//#region PARTNER_LINKS
export const PARTNER_LINKS = gql`
  query {
    partnerLinks {
      _id
      name
      displayName
    }
  }
`;

export const PARTNER_LINK = gql`
  query partnerLink($id: ID!) {
    partnerLink(partnerLinkId: $id) {
      _id
      name
      displayName
      linkUrl
      imageUrl
    }
  }
`;
//#endregion

//#region Golfers Users
export const GOLFERS_USERS_SEARCH = gql`
  query golfersUserList(
    $page: Int
    $pageSize: Int
    $sorting: UserSortInput
    $filter: UserSearchFilter
  ) {
    golfersUserList(
      page: $page
      pageSize: $pageSize
      sorting: $sorting
      filter: $filter
    ) {
      hits
      users {
        userId
        email
        registered
        placedOrders
        teetimeCount
        bookedCourses
        lastOrderDate
        avgPlayersPerOrder
        cancellationRate
      }
    }
  }
`;

export const GOLFERS_USER = gql`
  query golfersUser($id: ID!) {
    golfersUser(id: $id) {
      _id
      email
      firstName
      lastName
      language
      newsletter
      emailConfirmed
      deleted
      deletedAt
      createdAt
      updatedAt

      generalInfo {
        orderCount
        teetimeCount
        cancellationRate
        lastOrderDate
      }
      courseBookings {
        _id
        courseName
        teetimeCount
        liveTeetimes
        playerCount
        livePlayers
      }
      currencySums {
        currency
        sum
        liveSum
      }
    }
  }
`;

export const EMAIL_LIST_REGISTRATION_STATUS = gql`
  query emailListRegistrationStatus($emailList: [String]) {
    emailListRegistrationStatus(emailList: $emailList) {
      email
      registered
      verified
    }
  }
`;

export const EMAIL_REGISTRATION_STATUS = gql`
  query emailRegistrationStatus($email: String) {
    emailRegistrationStatus(email: $email) {
      email
      registered
      verified
    }
  }
`;
//#endregion

// #region  Email page
export const GET_EMAIL_RECIPIENTS = gql`
  query getEmailRecipients {
    getEmailRecipients{
      ${EMAIL_RECIPIENTS_ATTRIBUTES}
    }
  }
`;
export const GET_EMAIL_TEMPLATES = gql`
  query getEmailTemplates {
    getEmailTemplates{
      ${EMAIL_TEMPLATES_ATTRIBUTES}
    }
  }
`;
//#endregion

//#region Invoice
export const GET_INVOICE_LIST = gql`
  query invoiceList($pageIndex: Int!, $filter: InvoiceSearchFilter) {
    invoiceList(pageIndex: $pageIndex, filter: $filter) {
      _id
      name
      clubName
      courseName
      orderReference
      reference
      referenceTo {
        reference
      }
      createdAt
      fundsTransferred
      payoutDate
      total
      amountToCharge
      totalCommission
      status
    }
  }
`;

export const GET_INVOICE = gql`
  query invoice($id: ID!) {
    invoice(id: $id) {
      invoice {
        ${INVOICE_BASE_ATTRIBUTES}
      }
    }
  }
`;

export const INVOICE_SEARCH = gql`
  query invoiceSearch($filter: InvoiceSearchFilter) {
    invoiceSearch(filter: $filter) {
      matchCount
      pageCount
    }
  }
`;

export const INVOICE_GET_COMMENTS = gql`
  query getInvoiceComments($invoiceId: ID!) {
    getInvoiceComments(invoiceId: $invoiceId) {
      ${COMMENT_ATTRIBUTES}
    }
  }
`;

export const GET_PDF_FILE = gql`
  query getFile($id: ID!, $fileType: String!) {
    getFile(id: $id, fileType: $fileType) {
      ok
      returnMessage
      file {
        filename
        mimetype
        encoding
      }
    }
  }
`;

export const GET_PDF_SITE_FILE = gql`
  query generateSiteCommissionReport($siteId: ID!, $filter: YearMonthFilter) {
    generateSiteCommissionReport(siteId: $siteId, filter: $filter) {
      ok
      message
      files {
        filename
        mimetype
        encoding
      }
    }
  }
`;

export const GET_EXCEL_FILE = gql`
  query getExcelFile($filter: InvoiceSearchFilter) {
    getExcelFile(filter: $filter) {
      ok
      returnMessage
      file {
        filename
        mimetype
        encoding
      }
    }
  }
`;

export const GET_INVOICE_REPORT_PER_COURSE = gql`
  query getInvoicesReportPerCourse($filter: InvoiceSearchFilter) {
    getInvoicesByCoursePDF(filter: $filter) {
      ok
      returnMessage
      file {
        filename
        mimetype
        encoding
      }
    }
  }
`;

// #region Amadeus
export const LIST_VIRTUAL_CARDS = gql`
  query ListVirtualCards($pageIndex: Int!, $filter: VCCSearchFilter) {
    ListVirtualCards(pageIndex: $pageIndex, filter: $filter) {
      data {
        list {
          externalReference
          cardNumber
          cardHolder
          expiryDate
          status
        }
        pageCount
      }
      ok
      returnMessage
    }
  }
`;

export const GET_VIRTUAL_CARD_DETAILS = gql`
  query GetVirtualCardDetails(
    $References:[ReferencesInput]
  ) {
    GetVirtualCardDetails(
      References:$References
    ) {
      data {
        ${VIRTUAL_CARD_ATTRIBUTES}
      }
      ok
    }
  }
`;

// #endregion

// #region  Template
export const GET_TEMPLATES = gql`
  query getTemplates {
    getTemplates {
      ok
      templates {
        _id
        name
      }
    }
  }
`;
// #endregion

export const CLUB_PANEL_USERS = gql`
  query getClubPanelUsers {
    getClubPanelUsers {
      _id
      email
    }
  }
`;
