import React, { useContext } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import CardRow from "../../ui/CardRow";
import { TeeTimeDetails } from "./TeeTimeDetails";
import { OrderContext } from "../Order";

export function OrderCourseDetails({ course }) {
  const {
    state: { raw: order, previewLog }
  } = useContext(OrderContext);

  const getFieldPreview = (field) => {
    const clubIndex = order.order.clubs.findIndex(
      (c) => c._id === course.clubBookingId
    );

    if (clubIndex === -1) return null;

    return (
      previewLog &&
      previewLog.changes.find(
        (change) =>
          change.path.length === 3 &&
          change.path[0] === "clubs" &&
          change.path[1] === clubIndex &&
          change.path[2] === field
      )
    );
  };

  const totalFieldPreview = getFieldPreview("total");
  const payNowFieldPreview = getFieldPreview("payNowTotal");

  const ErrorLabel = () => {
    let errorStatus = "ERROR";
    const anyErr = course.teetimes.some((tt) => tt.bookingErrors?.length > 0);
    if (!anyErr) return null;

    if (course.teetimes.some((tt) => tt.bookingErrors?.length === 0))
      errorStatus = "PARTIAL";

    const textColor = errorStatus === "ERROR" ? "danger" : "warning";
    const text = errorStatus === "ERROR" ? "Failed" : "Partially failed";

    return (
      <p className={`text-uppercase font-weight-bold text-${textColor} mb-0`}>
        {text}
      </p>
    );
  };

  return (
    <Col xs={12} className="mb-4">
      <Card>
        <CardBody>
          <Row>
            <Col
              xs={12}
              sm={12}
              lg={2}
              className="border-lg-right border-md-bottom lg-border-right mb-4 mb-md-2"
            >
              <div className="text-center">
                <h3 className="mb-2">
                  <a
                    href={`${window.location.origin}/club/${course.clubSlug}?course=${course.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none font-weight-bold"
                  >
                    {course.name}
                  </a>
                </h3>
                <ErrorLabel />

                <div className="mt-3">
                  <Row>
                    <Col xs={12} sm={6}>
                      <CardRow label="Currency" value={course.currency} />
                    </Col>
                    <Col xs={12} sm={6}>
                      <CardRow
                        label="Total"
                        value={totalFieldPreview?.newValue ?? course.total}
                        customValueStyles={
                          totalFieldPreview
                            ? { backgroundColor: "#3dbfbe", color: "white" }
                            : undefined
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={12} sm={6}>
                      <CardRow
                        label="Prepaid"
                        value={
                          payNowFieldPreview?.newValue ?? course.payNowTotal
                        }
                        customValueStyles={
                          payNowFieldPreview
                            ? { backgroundColor: "#3dbfbe", color: "white" }
                            : undefined
                        }
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <CardRow label="VAT Rate" value={`${course.vatRate}%`} />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={12} sm={6}>
                      <CardRow
                        label="Provider"
                        value={course.teeSheetProvider}
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <CardRow
                        label="Stripe Service Fee"
                        value={course.stripeFee ?? "0.00"}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={12} sm={6}>
                      <CardRow
                        label="Provider Club ID"
                        value={course.teeSheetClubId ?? "N/A"}
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <CardRow
                        label="Provider Course ID"
                        value={course.teeSheetCourseId ?? "N/A"}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={12} lg={10}>
              {course.teetimes.map((tt, index) => (
                <TeeTimeDetails
                  localTimeZone={course.localTimeZone}
                  teetime={tt}
                  clubBookingId={course.clubBookingId}
                  provider={course.teeSheetProvider}
                  key={`tt-details-${index}`}
                  index={index}
                />
              ))}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
}
