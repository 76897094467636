// Vendor
import React, { useState, useContext } from "react";
import {
  Button,
  Popover,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { useMutation } from "@apollo/client";

// App
import { CANCEL_TEETIME_INTERNAL } from "../../../common/Mutations";
import Loader from "../../ui/Loader";
import { OrderContext } from "../Order";
import Info from "../../ui/Info";

export function InternalCancellation({
  teetimeId,
  refundableAmount,
  teetimePrice,
  payOnSite,
  disableRefund
}) {
  const [showModal, setShowModal] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [stripeRefund, setStripeRefund] = useState(false);
  const [fullRefund, setFullRefund] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [cancelTeetimeInternal, { loading }] = useMutation(
    CANCEL_TEETIME_INTERNAL,
    {
      onCompleted: ({ internalCancellation: res }) =>
        handleMutationComplete(res),
      onError: (err) => setHasError(true) && setErrorMsg(err.message)
    }
  );

  const {
    state: {
      raw,
      formatted: { _id: orderId }
    },
    dispatch
  } = useContext(OrderContext);

  function handleMutationComplete({ ok, message, object: order }) {
    if (ok) {
      toggleModal();
      setShowPopover(false); // Close the popover if it was open
      dispatch({ type: "RAW_DATA", payload: { ...raw, order } });
      dispatch({ type: "TRIGGER_REFETCH" });
    } else {
      setHasError(true);
      setErrorMsg(message);
    }
  }

  function handleCancel() {
    cancelTeetimeInternal({
      variables: {
        orderId,
        teetimeId,
        stripeRefund,
        fullRefund
      }
    });
  }

  function toggleModal() {
    setShowModal((prev) => !prev);
    setShowPopover(false);
  }

  function togglePopover() {
    setShowPopover((prev) => !prev);
    setHasError(false);
  }

  return (
    <div>
      <Button
        color="danger"
        className="mb-2 w-100"
        style={{ fontSize: "small" }}
        onClick={toggleModal}
      >
        Internal Cancellation
      </Button>

      <Modal isOpen={showModal} toggle={toggleModal} size="md">
        <ModalHeader toggle={toggleModal}>
          Confirm Internal Cancellation
        </ModalHeader>

        <ModalBody>
          {payOnSite ? (
            <p className="h3 font-weight-bold m-0">
              Tee time is either pay on site so no Stripe Refund will be done.
            </p>
          ) : (
            <>
              <p>Select options before proceeding with the cancellation:</p>
              <div className={`checkbox-group d-flex align-items-center w-fit`}>
                <input
                  id="stripe-refund-checkbox"
                  name="stripe-refund-checkbox"
                  type="checkbox"
                  disabled={payOnSite || disableRefund}
                  checked={stripeRefund}
                  onChange={(e) => {
                    setStripeRefund(e.target.checked);
                  }}
                />
                <label htmlFor="stripe-refund-checkbox" className="mb-0">
                  Stripe refund
                </label>
                <Info
                  value="if checked it will do automatic refund to customer on Stripe"
                  className="ml-2"
                />
              </div>
              <div
                className={`checkbox-group d-flex align-items-center w-fit mt-1`}
              >
                <input
                  id="full-refund-checkbox"
                  name="full-refund-checkbox"
                  type="checkbox"
                  checked={fullRefund}
                  disabled={!stripeRefund || payOnSite || disableRefund}
                  onChange={(e) => setFullRefund(e.target.checked)}
                />
                <label htmlFor="full-refund-checkbox" className="mb-0">
                  Full refund
                </label>
                <Info
                  value="if checked it will do entire refund to the customer including stripe fees"
                  className="ml-2"
                  id="full-refund-checkbox-info"
                />
              </div>

              {/* Refundable amount */}
              <div className="mt-4">
                {stripeRefund && (
                  <p className="h2">
                    Refundable amount:
                    <span className="h1 ml-1 font-weight-bold">
                      {fullRefund ? teetimePrice : refundableAmount}
                    </span>
                  </p>
                )}
              </div>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            color="danger"
            id="confirm-cancellation-button"
            onClick={togglePopover}
          >
            Confirm Cancellation
          </Button>

          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>

        <Popover
          placement="bottom"
          isOpen={showPopover}
          target="confirm-cancellation-button"
          toggle={togglePopover}
          container="body"
        >
          <PopoverBody className="p-3">
            <div className="mb-2">
              This will only cancel internally. Are you sure?
            </div>

            <div className="d-flex justify-content-around">
              <Button
                color="danger"
                size="sm"
                onClick={handleCancel}
                disabled={loading}
              >
                {loading ? <Loader /> : "Yes"}
              </Button>

              <Button color="secondary" size="sm" onClick={togglePopover}>
                Cancel
              </Button>
            </div>

            {hasError && (
              <p className="text-danger mt-2">
                Could not cancel teetime:
                <br />"{errorMsg}"
              </p>
            )}
          </PopoverBody>
        </Popover>
      </Modal>
    </div>
  );
}
