// Vendor
import React, { useState, useContext } from "react";
import { Button, Popover, PopoverBody } from "reactstrap";
import { useMutation } from "@apollo/client";

// App
import { CANCEL_TEETIME_PROVIDER } from "../../../common/Mutations";
import Loader from "../../ui/Loader";
import { OrderContext } from "../Order";

export function ProviderCancellationButton({ teetimeId, clubBookingId }) {
  const [showPopover, setShowPopover] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [cancelTeetime, { loading }] = useMutation(CANCEL_TEETIME_PROVIDER, {
    onCompleted: ({ cancellation: res }) => handleMutationComplete(res),
    onError: (err) => setHasError(true) && setErrorMsg(err.message)
  });

  const { dispatch } = useContext(OrderContext);

  function handleMutationComplete({ success, message }) {
    if (success) {
      toggle();
      dispatch({ type: "TRIGGER_REFETCH" });
    } else {
      setHasError(true);
      setErrorMsg(message);
    }
  }

  function handleCancel() {
    cancelTeetime({
      variables: {
        input: {
          clubBookingId,
          teeTimeBookingId: teetimeId
        }
      }
    });
  }

  function toggle() {
    setShowPopover((prev) => !prev);
    setHasError(false);
  }

  function handleBlur() {
    setShowPopover(false);
    setHasError(false);
  }

  // Prevent toggle from blur to trigger
  function handlePopoverClick(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  return (
    <div className="mb-2" onBlur={handleBlur}>
      <Button
        id={`btn-provider-cancel-${teetimeId}`}
        color="danger"
        onClick={toggle}
        className="w-100"
      >
        Cancel teetime
      </Button>

      <Popover
        onMouseDown={handlePopoverClick}
        placement="bottom"
        id="provider-cancel-confirm-popover"
        target={`btn-provider-cancel-${teetimeId}`}
        isOpen={showPopover}
        style={{ maxWidth: "200px" }}
        toggle={toggle}
      >
        <PopoverBody className="p-2">
          <div className="d-flex justify-content-center">
            <p>
              Send cancellation request to provider?
              <br />
              Automatic email confirmation on success.
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              className="flex-fill"
              color="danger"
              size="sm"
              disabled={loading}
              onClick={handleCancel}
            >
              {loading && <Loader />}
              {!loading && "Yes"}
            </Button>
          </div>
          {hasError && (
            <p className="text-danger">
              Could not cancel teetime:
              <br />"{errorMsg}"
            </p>
          )}
        </PopoverBody>
      </Popover>
    </div>
  );
}
