import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import { SiteContext } from "./Site";
import ReactSelect from "react-select";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { GET_PDF_SITE_FILE } from "../../common/Queries";
import { LayoutContext } from "../app/Layout";
import { saveBlobFile } from "../../common/DocumentHelper";
import Loader from "../ui/Loader";

const SiteCommissionModal = ({ isOpen, toggle }) => {
  const { state } = useContext(SiteContext);
  const { addAlert } = useContext(LayoutContext);

  const currentYear = moment().year();
  const [monthSelected, setMonthSelected] = useState(() => ({
    value: moment().month(),
    label: moment().format("MMMM")
  }));
  const [yearSelected, setYearSelected] = useState(() => ({
    value: moment().year(),
    label: moment().year()
  }));

  // Object that will be sent to the backend is called filter and will send
  const yearsPassed = [];

  // From 2023 - current year
  for (let year = currentYear; year >= 2023; year--) {
    yearsPassed.push(year);
  }

  const handleGetPdf = (year, month) => {
    generateSiteCommissionReport({
      variables: {
        siteId: state._id,
        filter: {
          year: year,
          month: month
        }
      }
    });
  };

  const handleOnCompleted = (res) => {
    if (res.generateSiteCommissionReport.ok) {
      const encodings = res.generateSiteCommissionReport.files.flatMap(
        (subArray) =>
          subArray.map((item) => ({
            encoding: item.encoding,
            fileName: item.filename
          }))
      );

      // Decode the base64 string into a pdf file using Buffer from
      const decodedPdfs = encodings.map((item) => {
        return {
          blob: Buffer.from(item.encoding, "base64"),
          fileName: item.fileName
        };
      });
      // Create a blob of the pdf file
      decodedPdfs.forEach((decoded) => {
        const blob = new Blob([new Uint8Array(decoded.blob)], {
          type: "application/pdf"
        });
        saveBlobFile(blob, decoded.fileName);
      });
    } else {
      // add alert
      addAlert({
        color: "danger",
        message:
          "Error while generating pdf file: " +
          res.generateSiteCommissionReport.message
      });
    }
  };

  const [generateSiteCommissionReport, { loading }] = useLazyQuery(
    GET_PDF_SITE_FILE,
    {
      onCompleted: handleOnCompleted,
      onError: () => {
        addAlert({
          color: "danger",
          message: "Error while generating pdf file"
        });
      },
      fetchPolicy: "no-cache"
    }
  );

  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <ModalHeader className="px-5 py-4" toggle={toggle}>
        Generate site report for <b>{state.name} site</b>
        <FontAwesomeIcon icon="pencil-alt" className="ml-2" />
      </ModalHeader>
      <ModalBody className="py-4 px-5 border">
        <div className="mb-2">
          <p>
            This generated report contains the all{" "}
            <span style={{ color: "#02c39a", fontWeight: "bold" }}>
              completed
            </span>{" "}
            invoices
          </p>
        </div>
        <div className="d-flex flex-column gap-2">
          <ReactSelect
            defaultValue={yearSelected}
            options={yearsPassed.map((year) => ({ value: year, label: year }))}
            onChange={(year) => {
              setYearSelected(year);
            }}
          />
          {/* Month selection */}
          <ReactSelect
            defaultValue={monthSelected}
            onChange={(month) => {
              setMonthSelected(month);
            }}
            options={[
              { label: "January", value: 0 },
              { label: "February", value: 1 },
              { label: "March", value: 2 },
              { label: "April", value: 3 },
              { label: "May", value: 4 },
              { label: "June", value: 5 },
              { label: "July", value: 6 },
              { label: "August", value: 7 },
              { label: "September", value: 8 },
              { label: "October", value: 9 },
              { label: "November", value: 10 },
              { label: "December", value: 11 }
            ]}
          />
        </div>
        <div>
          {!loading && (
            <Button
              className="mt-2"
              onClick={() => {
                handleGetPdf(yearSelected.value, monthSelected.value);
              }}
              disabled={loading}
            >
              Generate report
            </Button>
          )}
          {loading && (
            <div className="mt-2">
              <Loader size={50} />
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SiteCommissionModal;
