import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledTooltip } from "reactstrap";
import { OrderContext } from "../../orders/Order";
export default function LogsListExpanded({
  order,
  logs,
  expanded,
  setExpanded
}) {
  const {
    state: { previewLog },
    dispatch
  } = useContext(OrderContext);
  const generateChangeMessages = (order, changes) => {
    return changes.map((change) => {
      const { path, oldValue, newValue } = change;
      let message;

      switch (path[0]) {
        case "total":
          message = (
            <>
              Changed <span className="text-primary">order total</span> from{" "}
              <strong>{oldValue}</strong> to <strong>{newValue}</strong>.
            </>
          );
          break;
        case "payNowTotal":
          message = (
            <>
              Changed <span className="text-primary">pay now total</span> on
              order from <strong>{oldValue}</strong> to{" "}
              <strong>{newValue}</strong>.
            </>
          );
          break;
        case "clubs":
          const clubName = order.clubs[path[1]].name;
          if (path[2] === "total") {
            message = (
              <>
                Changed <span className="text-primary">club total</span> from{" "}
                <strong>{oldValue}</strong> to <strong>{newValue}</strong>.
              </>
            );
          } else if (path[2] === "payNowTotal") {
            message = (
              <>
                Changed club <span className="text-primary">pay now total</span>{" "}
                from <strong>{oldValue}</strong> to <strong>{newValue}</strong>.
              </>
            );
          } else if (path[2] === "teetimes" && path[4] === "price") {
            message = (
              <>
                Changed <span className="text-primary">price</span> for tee time
                from <strong>{oldValue}</strong> to <strong>{newValue}</strong>.
              </>
            );
          } else if (path[2] === "teetimes" && path[4] === "paidInAdvance") {
            message = (
              <>
                Changed <span className="text-primary">paid in advance</span>{" "}
                for tee time from <strong>{oldValue}</strong> to{" "}
                <strong>{newValue}</strong>.
              </>
            );
          } else if (path[2] === "teetimes" && path[4] === "fee") {
            message = (
              <>
                Changed <span className="text-primary">fee</span> for tee time
                from <strong>{oldValue}</strong> to <strong>{newValue}</strong>.
              </>
            );
          } else if (path[2] === "teetimes" && path[4] === "refundableAmount") {
            message = (
              <>
                Changed <span className="text-primary">refundable amount</span>{" "}
                for tee time from <strong>{oldValue}</strong> to{" "}
                <strong>{newValue}</strong>.
              </>
            );
          } else if (path[2] === "teetimes" && path[4] === "cancelled") {
            message = <>Tee time cancelled</>;
          }
          break;
        default:
          message = (
            <>
              Changed <span className="text-primary">{path.join(".")}</span>{" "}
              from <strong>{oldValue}</strong> to <strong>{newValue}</strong>.
            </>
          );
          break;
      }

      return message;
    });
  };

  const getClubName = (log) => {
    const clubChange = log.changes.find((change) => change.path[0] === "clubs");

    if (clubChange) {
      const clubIndex = clubChange.path[1];
      return order.clubs[clubIndex].name;
    } else {
      return "N/A";
    }
  };

  return (
    <div className="LogsContainer LogsContainer__expanded">
      <div className="container">
        <div className="row">
          <div className="col-8 align-self-center">
            <h4 className="m-0">Logs</h4>
          </div>
          <div className="col-4 text-right">
            <button
              className="btn btn-link text-danger"
              onClick={() => {
                setExpanded(!expanded);
                dispatch({ type: "PREVIEW_LOG", payload: null });
              }}
            >
              <FontAwesomeIcon
                icon="times"
                size="lg"
                id="close-comments-icon"
              />
              <UncontrolledTooltip
                delay={{ show: 0, hide: 0 }}
                placement="auto"
                target="close-comments-icon"
              >
                Close
              </UncontrolledTooltip>
            </button>
          </div>
        </div>
        <div className="d-flex flex-column flex-fill overflow-auto mt-3">
          {logs.map((log, i) => (
            <div key={log._id}>
              <div>
                <div className="mb-2">
                  <strong>
                    {new Date(log.createdAt).toLocaleString()} |{" "}
                    {log.createdBy.firstName} {log.createdBy.lastName}
                  </strong>
                  <div>
                    <strong>{getClubName(log)}</strong>
                  </div>
                </div>
                <ul>
                  {generateChangeMessages(order, log.changes).map(
                    (message, i) => (
                      <li key={i}>{message}</li>
                    )
                  )}
                </ul>
              </div>
              <div className="d-flex gap-2">
                <button
                  className={`btn ${
                    log._id === previewLog?._id ? "btn-success" : "btn-primary"
                  } text-white w-100`}
                  onClick={() =>
                    dispatch({ type: "PREVIEW_LOG", payload: log })
                  }
                >
                  Preview
                </button>
              </div>
              <hr></hr>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
