import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledTooltip } from "reactstrap";
import LogsListExpanded from "../../ui/Logs/LogsListExpanded";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
export default function OrderLogs({ order, logs }) {
  const [expanded, setExpanded] = useState(false);

  const LogsCounter = ({
    count,
    bgColor = "bg-info",
    className = "",
    id,
    tooltip
  }) => (
    <>
      <span
        className={`LogsCountIcon text-white ${bgColor} ${className}`}
        id={id}
      >
        {count}
      </span>
      <UncontrolledTooltip
        delay={{ show: 0, hide: 0 }}
        placement="auto"
        target={id}
      >
        {tooltip}
      </UncontrolledTooltip>
    </>
  );

  if (!expanded) {
    return (
      <div className="LogsContainer LogsContainer__minimized">
        <button
          className="my-3 btn btn-link"
          type="button"
          onClick={() => setExpanded(!expanded)}
        >
          <FontAwesomeIcon icon={faHistory} size="lg" id="logs-icon" />
          <UncontrolledTooltip
            delay={{ show: 0, hide: 0 }}
            placement="auto"
            target="logs-icon"
          >
            Show logs
          </UncontrolledTooltip>
        </button>
        <div className="d-flex h-auto align-items-baseline">
          <LogsCounter
            count={logs?.length}
            className="mr-1"
            id="logs-count-label"
            tooltip="Logs count"
          />
        </div>
      </div>
    );
  }

  return (
    <LogsListExpanded
      order={order}
      logs={logs}
      expanded={expanded}
      setExpanded={setExpanded}
    />
  );
}
