import React, { useContext } from "react";
import { Card, CardHeader, Container, Row, Col, CardBody } from "reactstrap";
import moment from "moment";
import { useQuery } from "@apollo/client";

import CardRow from "../../ui/CardRow";
import URLHelper from "../../../common/URLHelper";
import { OrderContext } from "../Order";
import { SITES } from "../../../common/Queries";
import { OrderCourseDetails } from "./OrderCourseDetails";
import { OrderEmails } from "./OrderEmails";

export function OrderGeneralInfo() {
  const golfersURL = `https://${
    URLHelper.isProduction() ? "greenfee365" : "golfers-frontend-stage.onrender"
  }.com`;

  const {
    state: { formatted: order, logs: logs, previewLog }
  } = useContext(OrderContext);
  const { data: sitesResult } = useQuery(SITES);

  if (!order) return null;

  const getFieldPreview = (field) => {
    return (
      previewLog &&
      previewLog.changes.find(
        (change) => change.path.length === 1 && change.path[0] === field
      )
    );
  };

  const totalFieldPreview = getFieldPreview("total");
  const payNowFieldPreview = getFieldPreview("payNowTotal");

  return (
    <Container className="p-0">
      <Card className="d-flex flex-column">
        <CardHeader className="border-bottom">Order General Info</CardHeader>
        <CardBody className="p-3">
          <Row>
            <Col xs={12} xl={3} className="border-right mb-4 mb-md-0">
              <CardRow label="Order ID" value={order._id} />
              <Row className="mt-3">
                <Col xs={12} sm={6}>
                  <CardRow
                    label="Order Reference"
                    value={order.orderReference}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <CardRow
                    label="Order Confirmation Page"
                    value={
                      <a
                        href={`${golfersURL}/order-confirmation/${order._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none"
                      >
                        Greenfee365.com
                      </a>
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} sm={6}>
                  <CardRow label="First Name" value={order.firstName} />
                </Col>
                <Col xs={12} sm={6}>
                  <CardRow label="Last Name" value={order.lastName} />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} sm={6}>
                  <CardRow label="Email" value={order.email} />
                </Col>
                <Col xs={12} sm={6}>
                  {sitesResult?.sites && (
                    <CardRow
                      label="Site"
                      value={
                        sitesResult.sites.find((x) => x._id === order.siteId)
                          ?.name || ""
                      }
                    />
                  )}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} sm={6}>
                  <CardRow label="Currency" value={order.currency} />
                </Col>
                <Col xs={12} sm={6}>
                  <CardRow
                    label="Total"
                    value={totalFieldPreview?.newValue ?? order.total}
                    customValueStyles={
                      totalFieldPreview
                        ? { backgroundColor: "#3dbfbe", color: "white" }
                        : undefined
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} sm={6}>
                  <CardRow
                    label="Prepaid"
                    value={payNowFieldPreview?.newValue ?? order.payNowTotal}
                    customValueStyles={
                      payNowFieldPreview
                        ? { backgroundColor: "#3dbfbe", color: "white" }
                        : undefined
                    }
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <CardRow label="Stripe Service Fee" value={order.stripeFee} />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col xs={12} sm={6}>
                  {order?.voucher && (
                    <CardRow
                      label="Voucher"
                      value={`(${order.voucher.code}) ${order.voucher.value}%`}
                    />
                  )}
                </Col>
                <Col xs={12} sm={6}>
                  {order.partnerNetwork && (
                    <CardRow
                      label="Partner Network"
                      value={order.partnerNetwork.name}
                    />
                  )}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} sm={6}>
                  <CardRow
                    label="Created At"
                    value={moment(order.createdAt).format("YYYY-MM-DD HH:mm")}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <CardRow
                    label="Updated At"
                    value={moment(order.updatedAt).format("YYYY-MM-DD HH:mm")}
                  />
                </Col>
              </Row>
              <div className="mt-4">
                <OrderEmails />
              </div>
            </Col>

            <Col xs={12} xl={9}>
              <Row>
                {order.courses.map((course, index) => (
                  <OrderCourseDetails key={index} course={course} />
                ))}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
}
