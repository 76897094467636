// vendor
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, useReducer } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Popover, PopoverBody } from "reactstrap";

// app
import TeeTimeTableList from "./timetables/TeeTimeTableList";
import SeasonList from "./seasons/SeasonList";
import ClubCourseGeneralInfo from "./categories/ClubCourseGeneralInfo";
import ClubCourseContactSelect from "./categories/ClubCourseContactSelect";
import ClubCourseAmenityList from "./categories/ClubCourseAmenityList";
import ClubCourseAreaList from "./categories/ClubCourseAreaList";
import ClubCourseLocation from "./categories/ClubCourseLocation";
import ClubCoursePresentation from "./categories/ClubCoursePresentation";
import { COURSE_UPDATE, COURSE_CREATE } from "../../../common/Mutations";
import { GET_EXCLUDED_COURSES_NEARBY } from "../../../common/Queries";
import ClubCourseImageList from "./categories/ClubCourseImageList";
import clubCourseReducer from "./clubCourseReducer";
import { useContext } from "react";
import { LayoutContext } from "../../app/Layout";
import ContactInfo from "../../ui/ContactInfo";
import { Alert } from "reactstrap";
import Loader from "../../ui/Loader";
import { ClubContext } from "../Club";
import Lazy from "../../ui/Lazy";
import ClubCourseTeeList from "./categories/ClubCourseTeeList";
import usePreventWindowUnload from "../../../hooks/usePreventWindowUnload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import ClubCourseProviderDetails from "./categories/ClubCourseProviderDetails";
import URLHelper from "../../../common/URLHelper";
import ClubCourseAmadeus from "./categories/ClubCourseAmadeus";
import ClubCourseReadMore from "./categories/ClubCourseReadMore";

export const ClubCourseContext = React.createContext(null);

function ClubCourse(props) {
  const { course, teeSheetProvider } = props;

  const saveBtnClick = () => {
    if (
      state.commissionType !== course.commissionType ||
      state.commission !== course.commission
    )
      setPopover(true);
    // Directly submit the form if commission is not changed
    else setSubmit(true);
  };

  // Middleware for form validation
  const handleSaveForm = (event, errors) => {
    if (errors.length)
      return addAlert({
        color: "danger",
        message: `Please fill out all required fields: (${errors?.length} remaining).`
      });

    handleSave();
  };

  async function handleSave() {
    const { clubId } = props;
    const courseId = course._id;

    // copy state as mutation input
    let input = { ...state };

    // not part of schema
    delete input._id;
    delete input.seasonRates;
    delete input.teeTimeTables;
    // Create
    if (!courseId) {
      // creating default season type
      input.seasonTypes = [
        {
          name: "Middle Season",
          type: "MIDDLE_SEASON",
          color: "#3DBFBE"
        }
      ];
      // creating default day type
      input.dayTypes = [
        {
          name: "Standard",
          type: "STANDARD"
        }
      ];
      createCourse({
        variables: {
          input,
          clubId
        }
      });
    }
    // Update
    else {
      // if user has selected the club's address, we need to empty the fields
      if (!hasOwnContactInfo) {
        // TODO:
        input.dailyOrderEmail =
          input.invoiceEmail =
          input.address =
          input.city =
          input.state =
          input.country =
          input.countryCode =
            "";
        input.invoiceEmails = [];
      }

      return updateCourse({
        variables: {
          input,
          courseId
        }
      });
    }
  }
  function handleCreateCompleted(data) {
    const payload = data.createCourse.course;
    setEdited(false);
    addAlert({ color: "success", message: "Course was successfully created" });
    dispatch({ type: "INIT", payload });
    clubDispatch({ type: "COURSES_SAVE_NEW", payload });
  }

  function handleCreateError() {
    addAlert({ color: "danger", message: "Could not create course" });
  }

  function handleUpdateCompleted(data) {
    if (!data.updateCourse.ok) {
      return handleUpdateError();
    }
    setEdited(false);
    addAlert({ color: "success", message: "Course was successfully saved" });
    dispatch({ type: "INIT", payload: data.updateCourse.course });
  }

  function handleUpdateError() {
    addAlert({ color: "danger", message: "Could not update course" });
  }

  const togglePopover = () => {
    setPopover(!popover);
  };

  function middleware(event, trigger = true) {
    if (!edited && trigger) setEdited(true);
    dispatch(event);
  }
  // hooks
  const [edited, setEdited] = useState(false);
  const [description, setDescription] = useState("");
  const [readMore, setReadMore] = useState("");
  const [i18n, setI18n] = useState(undefined);
  const [hasOwnContactInfo, setHasOwnContactInfo] = useState(false);
  const [popover, setPopover] = useState(false);
  const [submit, setSubmit] = useState(false);
  const formRef = useRef(null);
  usePreventWindowUnload(edited);

  const [state, dispatch] = useReducer(clubCourseReducer, null);
  const { addAlert } = useContext(LayoutContext);
  const { state: clubState, dispatch: clubDispatch } = useContext(ClubContext);

  const [createCourse, { loading: createLoading }] = useMutation(
    COURSE_CREATE,
    {
      onCompleted: handleCreateCompleted,
      onError: handleCreateError
    }
  );

  const [updateCourse, { loading: updateLoading }] = useMutation(
    COURSE_UPDATE,
    {
      onCompleted: handleUpdateCompleted,
      onError: handleUpdateError
    }
  );

  // update when 'course' prop change
  useEffect(() => {
    // check if course has its own contact info
    const { address, city, state, country, countryCode } = course;
    const fields = [address, city, state, country, countryCode];
    if (fields.some((field) => field && field !== ""))
      setHasOwnContactInfo(true);

    setDescription(course.description);
    setReadMore(course.readMore);
    setI18n(course.i18n);
    dispatch({
      type: "INIT",
      payload: course
    });
  }, [course]);

  //form submit through useEffect because of the popover and the additinal confirm button
  useEffect(() => {
    if (submit) {
      // Programmatically submit the form
      formRef.current.handleSubmit();

      // Reset the submit state
      setSubmit(false);
    }
  }, [submit]);

  /**
   * @param { boolean } toggleFlag
   */
  function handleContactInfoToggle(toggleFlag) {
    setHasOwnContactInfo(toggleFlag);
    const payload = {
      address: "",
      city: "",
      country: "",
      countryCode: "",
      postalCode: "",
      dailyOrderEmail: "",
      invoiceEmail: "",
      invoiceEmails: [],
      phone: "",
      state: "",
      website: ""
    };
    if (toggleFlag) {
      // Pre-fill fields with club data for user convenience
      Object.keys(payload).forEach((k) => (payload[k] = clubState[k] ?? ""));
    }
    middleware({ type: "MULTI_FIELD_SET", payload });
  }

  // guard
  if (!state) return null;

  return (
    <ClubCourseContext.Provider
      value={{ state, dispatch: middleware, saveCourse: handleSave }}
    >
      {(createLoading || updateLoading) && <Loader fullscreen />}
      <a
        className="float-right"
        href={`https://${
          URLHelper.isProduction()
            ? "greenfee365"
            : "golfers-frontend-stage.onrender"
        }.com/golf-course/${props.course.slug}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon="external-link-alt" className="mr-1" />
        Profile on Greenfee365.com
      </a>
      <AvForm onSubmit={handleSaveForm} ref={formRef}>
        <ClubCourseGeneralInfo />
        <ClubCourseProviderDetails teeSheetProvider={teeSheetProvider} />
        <ClubCourseAmadeus />
        <Lazy>
          <ClubCoursePresentation
            onChange={(event) => {
              const type = event.key === "description" ? "DESCRIPTION" : "I18N";
              middleware({ type, payload: event.value });
            }}
            description={state.description || description}
            descriptionOld={description}
            i18n={state.i18n || i18n}
            i18nOld={i18n}
          />
        </Lazy>
        <Lazy>
          <ClubCourseReadMore
            onChange={(event) => {
              const type = event.key === "readMore" ? "READ_MORE" : "I18N";
              middleware({ type, payload: event.value });
            }}
            description={state.readMore || readMore}
            descriptionOld={state.readMore}
            i18n={state.i18n || i18n}
            i18nOld={i18n}
          />
        </Lazy>
        <Lazy>
          <ClubCourseImageList />
        </Lazy>

        <ClubCourseTeeList />

        <ClubCourseContactSelect
          onChange={handleContactInfoToggle}
          hasOwnContactInfo={hasOwnContactInfo}
        />
        {hasOwnContactInfo && <ContactInfo context={ClubCourseContext} />}
        <Lazy>
          <ClubCourseLocation />
        </Lazy>
        <ClubCourseAreaList />
        <ClubCourseAmenityList />

        {teeSheetProvider === "GF365_SIMPLE" &&
          (state._id ? (
            <TeeTimeTableList courseId={state._id} />
          ) : (
            <div className="mt-5">
              <h3>Timetables</h3>
              <Alert color="warning">
                You must save your course before you can add timetables.
              </Alert>
            </div>
          ))}

        {state._id ? (
          <SeasonList />
        ) : (
          <div className="mt-5">
            <h3>Seasons</h3>
            <Alert color="warning">
              You must save your course before you can add seasons.
            </Alert>
          </div>
        )}
        <Popover isOpen={popover} placement="left" target="save-course">
          <PopoverBody>
            <div className="mb-2">
              Commission type or Commission value has been changed. This will
              update all season rates and their commission types and values that
              have isOverridableByCourse set to true
            </div>
            <Button
              className="ml-2"
              color="danger"
              size="sm"
              onClick={() => {
                setSubmit(true);
                togglePopover();
              }}
            >
              Save
            </Button>
            <Button
              className="ml-2"
              color="danger"
              size="sm"
              onClick={togglePopover}
            >
              Cancel
            </Button>
          </PopoverBody>
        </Popover>
        <Button
          size="lg"
          id="save-course"
          onClick={saveBtnClick}
          className="mt-4 float-right"
          color={edited ? "secondary" : "gray"}
          disabled={!edited && !!state._id}
        >
          Save
        </Button>
      </AvForm>
    </ClubCourseContext.Provider>
  );
}

ClubCourse.propTypes = {
  clubId: PropTypes.string.isRequired,
  teeSheetProvider: PropTypes.string,
  course: PropTypes.shape({
    _id: PropTypes.string,
    active: PropTypes.bool,
    address: PropTypes.string, // Optional override club level
    amenities: PropTypes.arrayOf(PropTypes.string),
    architect: PropTypes.string,
    areas: PropTypes.arrayOf(PropTypes.string),
    cancellationHours: PropTypes.number,
    payoutHours: PropTypes.number,
    daysOffset: PropTypes.number,
    claimed: PropTypes.bool, //.isRequired,
    claimedDate: PropTypes.string,
    city: PropTypes.string, // Optional override club level
    state: PropTypes.string, // Optional override club level
    country: PropTypes.string, // Optional override club level
    countryCode: PropTypes.string, // Optional override club level
    courseType: PropTypes.string,
    currency: PropTypes.string,
    description: PropTypes.string,
    guestPolicy: PropTypes.string,
    holes: PropTypes.number,
    i18n: PropTypes.object,
    linkedClubs: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string
      })
    ),
    loc: PropTypes.shape({
      coordinates: PropTypes.arrayOf(PropTypes.number)
    }),
    localTimeZone: PropTypes.string,
    membership: PropTypes.string,
    name: PropTypes.string,
    par: PropTypes.number,
    placeId: PropTypes.string,
    seasonRates: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        seasonType: PropTypes.shape({
          _id: PropTypes.string,
          name: PropTypes.string,
          type: PropTypes.string
        }),
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        prices: PropTypes.arrayOf(
          PropTypes.shape({
            //_id: PropTypes.string,
            dayType: PropTypes.shape({
              _id: PropTypes.string,
              name: PropTypes.string,
              type: PropTypes.string
            }),
            price: PropTypes.number,
            rackPrice: PropTypes.number,
            rateType: PropTypes.string,
            scheduleType: PropTypes.string,
            targetRateId: PropTypes.arrayOf(PropTypes.string)
          })
        ),
        buggyIncluded: PropTypes.bool
      })
    ),
    slug: PropTypes.string
  }).isRequired
};

export default ClubCourse;
