export default function orderReducer(state, action) {
  switch (action.type) {
    case "RAW_DATA":
      return { ...state, raw: action.payload, refetchFlag: false };
    case "FORMATTED_DATA": {
      return { ...state, formatted: action.payload };
    }
    case "COMMENTS_INIT": {
      return { ...state, comments: action.payload };
    }
    case "COMMENT_ADD": {
      return { ...state, comments: [action.payload, ...state.comments] };
    }
    case "COMMENT_UPDATE":
      return {
        ...state,
        comments: state.comments.map((comment) =>
          comment._id === action.payload._id ? action.payload : comment
        )
      };
    case "COMMENT_DELETE":
      return {
        ...state,
        comments: state.comments.filter(
          (comment) => comment._id !== action.payload._id
        )
      };
    case "COMMENT_CLOSE_TASK": {
      const commentsCopy = state.comments.map((comment) =>
        comment._id === action.payload._id ? action.payload : comment
      );
      return { ...state, comments: commentsCopy };
    }
    case "UPDATE_ORDER":
      return { ...state, refetchFlag: true };

    case "TRIGGER_REFETCH": {
      return { ...state, refetchFlag: true };
    }
    case "SET_TARGET_EMAIL": {
      return {
        ...state,
        targetEmail: { email: action.payload, active: state.targetEmail.active }
      };
    }
    case "TARGET_EMAIL_ACTIVE": {
      return {
        ...state,
        targetEmail: { email: state.targetEmail.email, active: action.payload }
      };
    }
    case "LOGS_INIT": {
      return { ...state, logs: action.payload };
    }
    case "PREVIEW_LOG": {
      return { ...state, previewLog: action.payload };
    }
    default:
      return state;
  }
}
